:root {
  --max-width: 700px;
  --horizontal-margin: 20px;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-container {
  display: grid;
  grid-template-columns:
    1fr
    var(--horizontal-margin)
    minmax(auto, var(--max-width))
    var(--horizontal-margin)
    1fr;
}

/* Centered with --horizontal-margin */
.main-container-child-centered {
  grid-column: 3;
}

.page {
  background-color: var(--chakra-colors-gray-50);
  color: var(--chakra-colors-gray-800);
  padding-top: 40px;
  padding-bottom: 70px;
}

.header {
  position: sticky;
  overflow: hidden;
  top: 0;
  background-color: var(--chakra-colors-gray-700);
  color: var(--chakra-colors-gray-50);
  padding-top: 25px;
  padding-bottom: 25px;
}

.header > nav {
  color: var(--chakra-colors-gray-50);
}

.header > nav > ul {
  display: flex;
}

.header > nav > ul > li:not(:last-child) {
  margin-right: 22px;
}

.nav-spacer {
  flex-grow: 1;
}

.footer {
  flex-grow: 1; /* Fill all remaining vertical space */
  background-color: var(--chakra-colors-gray-700);
  color: var(--chakra-colors-gray-50);
}

#footer-content {
  margin-top: 35px;
  margin-bottom: 35px;
}

/* Remove bullets from <ul> */
.list-style-type-none {
  list-style-type: none;
}

h1 {
  padding-bottom: 35px;
}

.error-message {
  color: var(--chakra-colors-red-500);
}

.bold {
  font-weight: bold;
}

.progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px 0;
  gap: 20px;
}

.center {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.settings-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media all and (min-width: 520px) {
  .settings-container {
    flex-direction: row;
    gap: 10px;
  }
}

.settings-container > :first-child {
  width: 150px;
  flex-shrink: 0;
}
